<template>
  <div>
    <MainLayout>
      <template v-slot:toolbar>
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <!--begin::Title-->
          <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Coupon - {{ coupon ? coupon.code : '' }}</h1>
          <!--end::Title-->
        </div>
        <!--begin::Actions-->
        <div class="d-flex align-items-center gap-2 gap-lg-3 position-relative">
          <div class="m-0">
            <router-link
              :to="{ name: 'coupons.index' }"
              class="btn btn-sm btn-primary"
            >
              Voltar
            </router-link>
          </div>
          <!--end::Filter menu-->
        </div>
        <!--end::Actions-->
        <!--end::Page title-->
      </template>

      <!-- CONTENT -->
      <!--begin::Navbar-->
      <div class="card mb-5 mb-xl-10">
        <div class="card-body pt-0 pb-0">
          <!--begin::Navs-->
          <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
            <!--begin::Nav item-->
            <li class="nav-item mt-2">
              <a
                class="nav-link text-active-primary ms-0 me-10 py-5"
                :class="{
                  active: currentTab === 'details'
                }"
                href="#"
                @click.prevent="changeTab('details')"
              >
                Detalhes
              </a>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item mt-2">
              <a
                class="nav-link text-active-primary ms-0 me-10 py-5"
                :class="{
                  active: currentTab === 'uses'
                }"
                href="#"
                @click.prevent="changeTab('uses')"
              >
                Utilizações
              </a>
            </li>
            <!--end::Nav item-->
          </ul>
          <!--begin::Navs-->
        </div>
      </div>
      <!--end::Navbar-->
      <!--begin::details View-->
      <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bold m-0">{{ tabTitle }}</h3>
          </div>
          <!--end::Card title-->
          <!--begin::Action-->
          <!--end::Action-->
        </div>
        <!--begin::Card header-->
        <!--begin::Card body-->
        <div class="card-body p-9">
          <template v-if="loader">
            <div class="py-5">
              <Loader size="big" />
            </div>
          </template>
          <template v-else>
            <!-- DETAILS TAB -->
            <div v-show="currentTab === 'details'">
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">ID</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ coupon.id }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Row-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Tipo</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8">
                  <span class="fw-semibold text-gray-800 fs-6">{{ coupon.type }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Nome</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ coupon.name }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Código</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ coupon.code }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Valor</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ coupon.amount/100 | formatMoney }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Início de validade para vinculação</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ coupon.start_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Término de validade para vinculação</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ coupon.end_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Número máximo de usos</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ coupon.max_uses }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Número de usos</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ `${coupon.uses.length}/${coupon.max_uses}` }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Apenas para primeira compra</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ coupon.is_first_purchase_use ? 'Sim' : 'Não' }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Uso único por advertiser</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ coupon.is_single_use_per_advertiser ? 'Sim' : 'Não' }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Uso único por conta</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ coupon.is_single_use_per_account ? 'Sim' : 'Não' }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Regras</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <ul>
                    <template v-if="coupon.type === 'CREDIT'">
                      <li>
                        <span class="fw-semibold text-gray-800 fs-6">Valor: {{ coupon.amount/100 | formatMoney }}</span>
                      </li>
                    </template>
                    <template v-else-if="coupon.type === 'SPEND_EARN'">
                      <li>
                        <span class="fw-semibold text-gray-800 fs-6">Gaste: {{ coupon.rules.SPEND_EARN.spend/100 | formatMoney }}</span>
                      </li>
                      <li>
                        <span class="fw-semibold text-gray-800 fs-6">Ganhe: {{ coupon.rules.SPEND_EARN.earn/100 | formatMoney }}</span>
                      </li>
                    </template>
                    <template v-if="coupon.rules">
                      <template v-if="coupon.rules.VALID_DATE">
                        <li>
                          <span class="fw-semibold text-gray-800 fs-6">Validade de vigência: {{ coupon.rules.VALID_DATE.valid_start_date | formatDate(('DD/MM/YYYY HH:mm:ss')) }} - {{ coupon.rules.VALID_DATE.valid_end_date | formatDate(('DD/MM/YYYY HH:mm:ss')) }}</span>
                        </li>
                      </template>
                    </template>
                  </ul>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7" v-if="coupon.description">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Descrição</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ coupon.description }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Status</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ coupon.is_active ? 'Ativo' : 'Desativado' }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Data de criação</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ coupon.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-7">
                <!--begin::Label-->
                <label class="col-lg-4 fw-semibold text-muted">Data de última atualização</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <span class="fw-semibold text-gray-800 fs-6">{{ coupon.updated_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <div class="d-flex flex-column flex-sm-row mt-3 justify-content-center gap-3">
                <!-- Edit -->
                <router-link
                  :to="{ name: 'coupons.edit', params: { id: coupon.id } }"
                  class="btn btn-primary"
                >
                  Editar
                </router-link><!-- Edit -->
              </div>
            </div> <!-- END DETAILS TAB -->
            <!-- USES TAB -->
            <div v-show="currentTab === 'uses'">
              <p class="text-center" v-if="coupon.uses.length === 0">
                Esse cupom não foi utilizado
              </p>
              <div v-else>
                <!--begin::Table-->
                <table class="table align-middle table-row-dashed fs-6 gy-3" id="kt_table_widget_5_table">
                  <!--begin::Table head-->
                  <thead>
                    <!--begin::Table row-->
                    <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                      <th class="min-w-100px">Anunciante</th>
                      <th class="pe-3 min-w-100px">Data da vinculação</th>
                      <th class="pe-3 min-w-150px">Valor</th>
                      <th class="pe-3 min-w-100px">Valor remanescente</th>
                      <th class="pe-3 min-w-100px">Início de vigência</th>
                      <th class="pe-3 min-w-100px">Término de vigência</th>
                    </tr>
                    <!--end::Table row-->
                  </thead>
                  <!--end::Table head-->
                  <!--begin::Table body-->
                  <tbody class="fw-bold text-gray-600">
                    <tr v-for="(use, index) in coupon.uses" :key="index">
                      <td class="">
                        <router-link
                          :to="{ name: 'advertisers.show', params: { id: use.advertiser_id } }"
                        >
                          {{ use.advertiser_name }}
                        </router-link>
                      </td>
                      <td class="">{{ use.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</td>
                      <td class="">{{ use.amount/100 | formatMoney }}</td>
                      <td class="">{{ use.remaining_amount / 100 | formatMoney }}</td>
                      <td class="">{{ use.valid_start_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</td>
                      <td class="">{{ use.valid_end_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</td>
                    </tr>
                  </tbody>
                  <!--end::Table body-->
                </table>
                <!--end::Table-->
              </div>
            </div> <!-- USES TAB -->
          </template>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::details View-->
      <!-- END CONTENT -->
    </MainLayout>
  </div>
</template>

<script>

import CouponsService from '@/modules/coupons/services/coupons-service'

import Loader from '@/components/common/Loader/Loader'
import MainLayout from '@/components/layouts/MainLayout'

export default {
  components: {
    Loader,
    MainLayout
  },
  metaInfo () {
    return {
      title: this.coupon ? this.coupon.code : ''
    }
  },
  data () {
    return {
      coupon: '',
      currentTab: 'details',
      loader: false
    }
  },
  created () {
    this.getCoupon()
  },
  computed: {
    /**
     * Current tab title
     */
    tabTitle () {
      switch (this.currentTab) {
        case 'uses':
          return 'Utilizações'
        default:
          return 'Detalhes'
      }
    }
  },
  methods: {
    /**
     * Change current tab
     */
    changeTab (tab) {
      this.currentTab = tab
    },
    /**
     * Get coupon
     */
    async getCoupon () {
      this.loader = true
      try {
        const coupon = await CouponsService.getCoupon(this.$route.params.id)
        this.coupon = coupon
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    }
  }
}
</script>
